import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "userCenter"
    }
  }, [!_vm.aliPayLife && !_vm.wxBrowser ? _c("div", {
    staticClass: "nav"
  }, [_c("div", {
    staticClass: "nav-back",
    on: {
      click: function click($event) {
        return _vm.$router.push("/home");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-zuojiantou",
    staticStyle: {
      "font-size": "20px",
      "font-weight": "600"
    }
  })]), _c("div", {
    staticClass: "nav-title"
  }, [_vm._v(_vm._s(_vm.$route.meta.title))])]) : _vm._e(), _c("header", {
    staticClass: "userCard"
  }, [_c("div", {
    staticClass: "userCard-img"
  }, [_vm.userInfo.headImgUrl == "" || _vm.userInfo.headImgUrl == null ? _c("img", {
    staticStyle: {
      width: "56px",
      height: "56px"
    },
    attrs: {
      width: "100%",
      src: "https://oss.globalcharge.cn/prod/wechat/image/defaultHeadImg.png",
      alt: "头像"
    }
  }) : _c("img", {
    staticStyle: {
      width: "56px",
      height: "56px"
    },
    attrs: {
      width: "100%",
      src: _vm.userInfo.headImgUrl,
      alt: "头像"
    }
  })]), _c("div", {
    staticClass: "userCard-info"
  }, [_c("div", {
    staticClass: "userCard-info-name"
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.nickName || "昵称"))]), _vm.userInfo.member ? _c("span", [_vm._v("会员")]) : _vm._e()]), _c("router-link", {
    staticClass: "userCard-info-point",
    attrs: {
      tag: "div",
      to: "/user/point-record"
    }
  }, [_vm._v("我的积分：" + _vm._s(_vm.userInfo.point || "0") + "分")])], 1)]), _vm.subscribeOffice === false ? _c("img", {
    staticStyle: {
      width: "100%",
      "margin-top": "10px"
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/image/guanzhu3.png",
      alt: "关注"
    },
    on: {
      click: _vm.toGongZhongHao
    }
  }) : _vm._e(), _c("img", {
    staticClass: "vipArtBanner",
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      width: "100%",
      src: require("@@/userCenter/vipArtBanner.png"),
      alt: "积分当钱花"
    },
    on: {
      click: _vm.goToShareFriends
    }
  }), _c("div", {
    staticClass: "card pointCard"
  }, [_c("p", {
    staticClass: "card-title"
  }, [_c("em", [_vm._v("积分详情")]), _c("span", {
    attrs: {
      tag: "span"
    },
    on: {
      click: _vm.goToShareFriends
    }
  }, [_vm._v("如何获得积分？")])]), _c("ul", {
    staticClass: "card-body"
  }, [_c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/share-record"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.inviteFriend || 0))]), _c("p", [_vm._v("邀请好友")])]), _c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/point-record?operation=DEDUCT"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.pointOfUsed))]), _c("p", [_vm._v("已使用积分")])]), _c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/point-record"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.pointOfTotal))]), _c("p", [_vm._v("累计积分")])])], 1)]), _c("div", {
    staticClass: "card rechargeCard"
  }, [_vm._m(0), _c("ul", {
    staticClass: "card-body"
  }, [_c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/coupon"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.couponNumber))]), _c("p", [_vm._v("优惠券")])]), _c("router-link", {
    staticClass: "card-body-item",
    attrs: {
      tag: "li",
      to: "/user/recharge-record"
    }
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.chargeCount))]), _c("p", [_vm._v("充值记录")])]), _c("li", {
    staticClass: "card-body-item"
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.payAmount))]), _c("p", [_vm._v("消费金额")])]), _c("li", {
    staticClass: "card-body-item"
  }, [_c("em", [_vm._v(_vm._s(_vm.userInfo.saveAmount))]), _c("p", [_vm._v("已省金额")])])], 1)]), !_vm.aliPayLife ? _c("div", {
    staticClass: "card serveCard"
  }, [_vm._m(1), _c("ul", {
    staticClass: "card-body"
  }, [_c("li", {
    staticClass: "card-body-item",
    on: {
      click: _vm.toFanBase
    }
  }, [_c("em", [_c("zch-icon", {
    staticClass: "message_fill",
    attrs: {
      icon: "message_fill"
    }
  })], 1), _c("p", [_vm._v("加入海外粉丝群")])]), _c("li", {
    staticClass: "card-body-item",
    on: {
      click: _vm.toKeFu
    }
  }, [_c("em", [_c("zch-icon", {
    staticClass: "smile_fill",
    attrs: {
      icon: "smile_fill"
    }
  })], 1), _c("p", [_vm._v("联系客服")])])])]) : _vm._e(), _c("van-popup", {
    staticClass: "fansGroupPopup",
    model: {
      value: _vm.fansGroupVisible,
      callback: function callback($$v) {
        _vm.fansGroupVisible = $$v;
      },
      expression: "fansGroupVisible"
    }
  }, [_c("img", {
    staticStyle: {
      "border-radius": "10px",
      "max-width": "500px",
      "min-width": "280px"
    },
    attrs: {
      width: "100%",
      src: "https://oss.globalcharge.cn/prod/miniProgram/fansGroup.png",
      alt: "扫码加群"
    }
  })]), _c("van-popup", {
    staticClass: "miniprogramPopup",
    model: {
      value: _vm.miniprogramVisible,
      callback: function callback($$v) {
        _vm.miniprogramVisible = $$v;
      },
      expression: "miniprogramVisible"
    }
  }, [_c("zch-miniprogram-code")], 1), _c("van-popup", {
    staticClass: "fansGroupPopup",
    model: {
      value: _vm.isSubscribeVisiable,
      callback: function callback($$v) {
        _vm.isSubscribeVisiable = $$v;
      },
      expression: "isSubscribeVisiable"
    }
  }, [_c("img", {
    staticStyle: {
      "border-radius": "10px",
      "max-width": "500px",
      "min-width": "300px"
    },
    attrs: {
      width: "100%",
      src: "https://oss.globalcharge.cn/prod/wechat/image/officeGuideSubscribe.jpg",
      alt: "关注公众号"
    }
  })]), _c("van-dialog", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      title: "长按扫码联系客服"
    },
    model: {
      value: _vm.showComponyWXImg,
      callback: function callback($$v) {
        _vm.showComponyWXImg = $$v;
      },
      expression: "showComponyWXImg"
    }
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/companyWx.png"),
      width: "90%"
    }
  })])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "card-title"
  }, [_c("em", [_vm._v("充值详情")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "card-title"
  }, [_c("em", [_vm._v("服务帮助")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };