import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "core-js/modules/es.function.name.js";
import RecordList from "./components/RecordList";
export default {
  name: "PointRecord",
  components: _defineProperty(_defineProperty({
    RecordList: RecordList
  }, _Tab.name, _Tab), _Tabs.name, _Tabs),
  data: function data() {
    return {
      active: "IN"
    };
  },
  created: function created() {
    this.active = this.$route.query.operation || "IN";
  }
};