import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "paySuccess-box"
  }, [_c("zch-subscribe", {
    attrs: {
      "subscribe-visible": _vm.subscribeVisible,
      "beach-recharge-bol": _vm.beachRechargeBol,
      "show-member": _vm.showMember
    },
    on: {
      close: function close($event) {
        _vm.subscribeVisible = $event;
      }
    }
  }), _c("div", {
    staticClass: "header"
  }, [_c("img", {
    attrs: {
      src: _vm.OSSURL + "/wechat/image/paySuccess/payRight.png"
    }
  }), _c("span", {
    staticStyle: {
      color: "#265cfe"
    }
  }, [_vm._v("支付成功,")]), _c("span", {
    staticStyle: {
      color: "#787878"
    }
  }, [_vm._v("请关注到账通知")])]), _c("div", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("img", {
    staticClass: "banner",
    attrs: {
      src: _vm.OSSURL + "/wechat/image/paySuccess/payBanner.png"
    },
    on: {
      click: _vm.toShareFriends
    }
  })]), _c("div", {
    staticClass: "order-detail1"
  }, [_c("div", {
    staticClass: "orderInfo"
  }, [_vm._v("订单信息")]), _c("div", {
    staticClass: "orderInfo-content"
  }, [_c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("运营商 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.paySuccess.cname) + "-" + _vm._s(_vm.paySuccess.displayCarrierName || _vm.paySuccess.carrier) + "运营商 ")])]), _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("产品 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.paySuccess.name || _vm.paySuccess.productName) + " " + _vm._s(_vm.paySuccess.type === "HF" ? "话费" : _vm.paySuccess.type === "LL" ? "流量" : "套餐") + " ")])]), _vm.isMyTaxValueArrItem() && _vm.paySuccess.productType === "HF" ? _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("预计实际到账 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm._f("toFixed2")(_vm.paySuccess.baseValue * (1 - _vm.currentTipsInfo.taxValue / 100))) + " " + _vm._s(_vm.paySuccess.unit) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("充值号码 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" +" + _vm._s(_vm.paySuccess.areaNumber) + " " + _vm._s(_vm.dealAccount(_vm.paySuccess.account)) + " ")])]), _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("支付方式 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.paySuccess.payment === "ali_pay" ? "支付宝" : "微信") + " ")])]), _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("支付时间 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.getFormDateTime(_vm.paySuccess.orderTime || _vm.paySuccess.created)) + " ")])]), _vm.paySuccess.isBatch ? _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("订单金额 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v("￥" + _vm._s(_vm.paySuccess.payAmount || _vm.paySuccess.payPrice))])]) : _vm._e(), !_vm.paySuccess.isBatch ? _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("订单金额 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" ￥" + _vm._s(_vm.paySuccess.originalPayAmount || _vm.paySuccess.originalPrice) + " ")])]) : _vm._e(), _vm.paySuccess.selection != null && !_vm.paySuccess.isBatch ? _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v(" 优惠金额 : "), _c("span", {
    staticStyle: {
      "font-size": "12px",
      "margin-left": "5px"
    }
  }, [_vm._v(_vm._s(_vm.paySuccess.selection === "point" && "积分" || _vm.paySuccess.selection === "coupon" && "优惠券" || ""))])]), _c("div", {
    staticClass: "right",
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" -￥" + _vm._s(_vm._f("toFixed2")(_vm.paySuccess.originalPayAmount - _vm.paySuccess.payAmount)) + " ")])]) : _vm._e(), _vm.paySuccess.selected != null && !_vm.paySuccess.isBatch || this.isFirstDiscount ? _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v(" 优惠金额 : "), _c("span", {
    staticStyle: {
      "font-size": "12px",
      "margin-left": "5px"
    }
  }, [_vm._v(_vm._s(_vm.paySuccess.selected === "point" && "积分" || _vm.paySuccess.selected === "coupon" && "优惠券" || this.isFirstDiscount && "首单优惠" || ""))])]), _c("div", {
    staticClass: "right",
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" -￥" + _vm._s(_vm._f("toFixed2")(_vm.paySuccess.originalPrice - _vm.paySuccess.payPrice)) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "dashed"
  }), _c("div", {
    staticClass: "orderInfo-content-item"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("实付款 :")]), _c("div", {
    staticClass: "right",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "600",
      color: "#000"
    }
  }, [_vm._v(" ￥" + _vm._s(_vm.paySuccess.payAmount || _vm.paySuccess.payPrice) + " ")])]), _vm.isMyTaxValueArrItem() && _vm.paySuccess.productType === "HF" ? _c("div", {
    staticClass: "orderInfo-content-item2"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("备注 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" 每笔话费充值，" + _vm._s(_vm.paySuccess.cname) + "政府均收取" + _vm._s(_vm.currentTipsInfo.taxValue) + "%税款；实际到账以到账短信为准！此不作为退款理由。 ")])]) : _vm._e()])]), _c("div", {
    staticClass: "my-btns-box"
  }, [_c("div", {
    staticClass: "goShopping my-btns",
    on: {
      click: _vm.toIndex
    }
  }, [_vm._v("继续充值")]), _c("div", {
    staticClass: "watchOrder my-btns",
    on: {
      click: _vm.toDetail
    }
  }, [_vm._v("查看订单")])]), _c("div", {
    staticClass: "order-detail2"
  }, [_c("div", {
    staticClass: "gongZhongHao",
    on: {
      click: function click($event) {
        return _vm.changeNum(1);
      }
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: _vm.OSSURL + "/wechat/image/paySuccess/qunliao.png",
      alt: "logo"
    }
  })]), _vm._m(0), _c("div", {
    staticClass: "guanzhu"
  }, [_vm._v("立即加入")])])]), _c("div", {
    staticClass: "gongZhongHao",
    on: {
      click: function click($event) {
        return _vm.changeNum(2);
      }
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: _vm.OSSURL + "/wechat/image/paySuccess/inform.png",
      alt: "logo"
    }
  })]), _vm._m(1), _c("div", {
    staticClass: "guanzhu2"
  }, [_vm._v("立即关注")])])]), _c("div", {
    staticClass: "gongZhongHao",
    on: {
      click: function click($event) {
        return _vm.changeNum(3);
      }
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: _vm.OSSURL + "/wechat/image/paySuccess/lingdang.png",
      alt: "logo"
    }
  })]), _vm._m(2), _c("div", {
    staticClass: "guanzhu2"
  }, [_vm._v("立即关注")])])])]), _c("van-popup", {
    staticStyle: {
      "text-align": "center",
      "background-color": "transparent"
    },
    model: {
      value: _vm.showVisible,
      callback: function callback($$v) {
        _vm.showVisible = $$v;
      },
      expression: "showVisible"
    }
  }, [_vm.num === 1 ? _c("img", {
    staticStyle: {
      width: "320px",
      height: "490px",
      "border-radius": "10px"
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/image/5000.png"
    }
  }) : _vm._e(), _vm.num === 2 ? _c("img", {
    staticStyle: {
      width: "320px",
      height: "490px",
      "border-radius": "10px"
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/image/10000.png"
    }
  }) : _vm._e(), _vm.num === 3 ? _c("img", {
    staticStyle: {
      width: "320px",
      height: "430px",
      "border-radius": "10px"
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/image/videoQrCode.jpg",
      alt: ""
    }
  }) : _vm._e()]), _c("van-popup", {
    staticStyle: {
      "text-align": "center",
      "background-color": "transparent"
    },
    model: {
      value: _vm.isShowPublicAccountQRCode,
      callback: function callback($$v) {
        _vm.isShowPublicAccountQRCode = $$v;
      },
      expression: "isShowPublicAccountQRCode"
    }
  }, [_c("img", {
    staticStyle: {
      width: "300px",
      height: "300px",
      "border-radius": "10px"
    },
    attrs: {
      src: require("../../../src/assets/WxFollowThePublicAccountQrCode.png"),
      alt: ""
    }
  })])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("拉近全球华人距离,让海外生活更便利")]), _c("div", {
    staticClass: "desc"
  }, [_c("span", {
    staticStyle: {
      color: "#2c53fc",
      "font-weight": "600"
    }
  }, [_vm._v("50000+")]), _vm._v("海外华人社区 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("及时接收到账通知")]), _c("div", {
    staticClass: "desc"
  }, [_c("span", {
    staticStyle: {
      color: "#2c53fc",
      "font-weight": "600"
    }
  }, [_vm._v("100000+")]), _vm._v("华人都在关注 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("充值攻略 | 全球热点 | 海外生活")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v(" 您身边可信赖的海外资讯"), _c("span", {
    staticStyle: {
      color: "#2c53fc",
      "font-weight": "600"
    }
  }, [_vm._v("视频号")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };