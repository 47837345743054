var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "all"
  }, [_c("div", {
    staticClass: "coupon",
    attrs: {
      id: "userCoupon"
    }
  }, [_c("div", {
    staticClass: "first-nav-button"
  }, [_c("div", {
    staticClass: "notuse-nav",
    class: {
      select: _vm.active == 0
    },
    on: {
      click: function click($event) {
        return _vm.changeTable(0);
      }
    }
  }, [_c("span", [_vm._v("未使用")])]), _c("div", {
    staticClass: "lose-nav",
    class: {
      select: _vm.active == 1
    },
    on: {
      click: function click($event) {
        return _vm.changeTable(1);
      }
    }
  }, [_c("span", [_vm._v("已失效")])])]), _vm.active == 0 ? _c("div", {
    staticClass: "not-use",
    staticStyle: {
      height: "100%",
      overflow: "auto"
    }
  }, [_c("div", {
    staticClass: "nav"
  }, [_c("div", {
    staticClass: "all",
    class: {
      selected: _vm.activeName == 1
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(1);
      }
    }
  }, [_c("p", [_vm._v("全部")])]), _c("div", {
    staticClass: "be-common",
    class: {
      selected: _vm.activeName == 2
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(2);
      }
    }
  }, [_c("p", [_vm._v("通用券")])]), _c("div", {
    staticClass: "hf",
    class: {
      selected: _vm.activeName == 3
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(3);
      }
    }
  }, [_c("p", [_vm._v("话费券")])]), _c("div", {
    staticClass: "ll",
    class: {
      selected: _vm.activeName == 4
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(4);
      }
    }
  }, [_c("p", [_vm._v("流量券")])]), _c("div", {
    staticClass: "ll",
    class: {
      selected: _vm.activeName == 5
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(5);
      }
    }
  }, [_c("p", [_vm._v("套餐券")])])])]) : _vm._e(), _vm.active == 0 ? _c("div", {
    staticStyle: {
      padding: "10px 0",
      "background-color": "#f2f2f2"
    }
  }, [_c("div", {
    staticStyle: {
      "background-color": "#f2f2f2",
      "font-size": "14px",
      height: "20px"
    }
  }, [_c("span", {
    staticStyle: {
      padding: "10px 15px",
      position: "relative",
      top: "5px",
      color: "#999"
    }
  }, [_vm._v("开启优惠券过期/到账提醒")]), _c("span", {
    staticStyle: {
      padding: "5px",
      color: "#ae8f63",
      border: "#ae8f63 1px solid",
      "border-radius": "10px",
      position: "absolute",
      right: "10px"
    }
  }, [_vm._v(" 订阅")]), _c("wx-open-subscribe", {
    staticStyle: {
      width: "45px",
      height: "40px",
      background: "red",
      position: "absolute",
      right: "5px",
      opacity: "0"
    },
    attrs: {
      template: _vm.subTemplateId,
      id: "subscribe-btn"
    },
    on: {
      success: _vm.success2,
      error: _vm.subError2
    }
  }, [_c("script", {
    attrs: {
      type: "text/wxtag-template"
    }
  }, [_vm._v(' <style> .btn2 { width: 45px; height: 50px; border: none; padding: 15px 0; color: red; } </style> <button class="btn2">立即参与</button> ')])])], 1)]) : _vm._e(), _c("div", {
    staticClass: "list"
  }, [_vm.active == 0 ? _c("van-list", {
    attrs: {
      finished: _vm.finished1
    },
    on: {
      load: _vm.onLoad1
    },
    model: {
      value: _vm.loading1,
      callback: function callback($$v) {
        _vm.loading1 = $$v;
      },
      expression: "loading1"
    }
  }, [_c("coupon-list", {
    attrs: {
      active: _vm.active,
      "coupon-list": _vm.showNotUseFilterList
    }
  })], 1) : _vm._e()], 1), _vm.active == 1 ? _c("div", {
    staticClass: "not-use grayColor",
    staticStyle: {
      height: "100%",
      overflow: "auto"
    }
  }, [_c("div", {
    staticClass: "nav"
  }, [_c("div", {
    staticClass: "all",
    class: {
      selected: _vm.activeName == 6
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(6);
      }
    }
  }, [_c("p", [_vm._v("全部")])]), _c("div", {
    staticClass: "be-common",
    class: {
      selected: _vm.activeName == 7
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(7);
      }
    }
  }, [_c("p", [_vm._v("已使用")])]), _c("div", {
    staticClass: "hf",
    class: {
      selected: _vm.activeName == 8
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(8);
      }
    }
  }, [_c("p", [_vm._v("已过期")])]), _c("div", {
    staticClass: "hf",
    class: {
      selected: _vm.activeName == 9
    },
    on: {
      click: function click($event) {
        return _vm.changeActiveStatus(9);
      }
    }
  }, [_c("p", [_vm._v("使用中")])])])]) : _vm._e(), _vm.active == 1 ? _c("div", {
    staticStyle: {
      padding: "10px 0",
      "background-color": "#f2f2f2"
    }
  }, [_c("div", {
    staticStyle: {
      "background-color": "#f2f2f2",
      "font-size": "14px",
      height: "20px"
    }
  }, [_c("span", {
    staticStyle: {
      padding: "10px 15px",
      position: "relative",
      top: "5px",
      color: "#999"
    }
  }, [_vm._v("开启优惠券过期/到账提醒")]), _c("span", {
    staticStyle: {
      padding: "5px",
      color: "#ae8f63",
      border: "#ae8f63 1px solid",
      "border-radius": "10px",
      position: "absolute",
      right: "10px"
    }
  }, [_vm._v(" 订阅")]), _c("wx-open-subscribe", {
    staticStyle: {
      width: "45px",
      height: "40px",
      background: "red",
      position: "absolute",
      right: "5px",
      opacity: "0"
    },
    attrs: {
      template: _vm.subTemplateId,
      id: "subscribe-btn"
    },
    on: {
      success: _vm.success2,
      error: _vm.subError2
    }
  }, [_c("script", {
    attrs: {
      type: "text/wxtag-template"
    }
  }, [_vm._v(' <style> .btn2 { width: 45px; height: 50px; border: none; padding: 15px 0; color: red; } </style> <button class="btn2">立即参与</button> ')])])], 1)]) : _vm._e(), _vm.active == 1 ? _c("van-list", {
    attrs: {
      finished: _vm.finished2
    },
    on: {
      load: _vm.onLoad2
    },
    model: {
      value: _vm.loading2,
      callback: function callback($$v) {
        _vm.loading2 = $$v;
      },
      expression: "loading2"
    }
  }, [_c("coupon-list", {
    attrs: {
      active: _vm.active,
      "coupon-list": _vm.showLapseFilterList
    }
  })], 1) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };