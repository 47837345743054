var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {}, [_vm.countryName ? _c("div", {
    staticStyle: {
      position: "absolute",
      color: "#424242",
      "font-weight": "bold",
      "font-size": "14px",
      "background-color": "#fff",
      padding: "5px 10px",
      "border-radius": "15px"
    },
    style: {
      top: "".concat(_vm.myheight * 0.02, "px"),
      left: "".concat(_vm.mywidth * 0.05, "px")
    }
  }, [_vm._v(_vm._s(_vm.countryName))]) : _vm._e(), _c("div", {
    staticStyle: {
      position: "absolute",
      color: "#474747",
      "font-weight": "bold",
      "font-size": "16px"
    },
    style: {
      top: "".concat(_vm.myheight * 0.32, "px"),
      left: "".concat(_vm.mywidth * 0.23, "px")
    }
  }, [_vm._v(" " + _vm._s(_vm.roomBaseName) + " ")]), _c("img", {
    staticStyle: {
      position: "absolute"
    },
    style: {
      top: "".concat(_vm.myheight * 0.41, "px"),
      left: "".concat(_vm.mywidth * 0.34, "px"),
      width: "".concat(_vm.mywidth * 0.35, "px"),
      height: "".concat(_vm.mywidth * 0.35, "px")
    },
    attrs: {
      src: _vm.qrUrl,
      alt: ""
    }
  }), _c("img", {
    style: {
      width: "".concat(_vm.mywidth, "px"),
      height: "".concat(_vm.myheight, "px")
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/src/assets/userCenter/groupChat.png",
      width: "100%",
      alt: ""
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };