import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import ZchMember from "@/components/ZchMember";
export default {
  name: "ZchSubscribe",
  components: _defineProperty(_defineProperty({}, _Popup.name, _Popup), "ZchMember", ZchMember),
  props: {
    subscribeVisible: Boolean,
    beachRechargeBol: Boolean,
    showMember: Boolean
  },
  created: function created() {
    console.log("当前的 subTemplateId ==》", this.subTemplateId);
  },
  computed: {
    _waitOrderVisible: {
      set: function set(value) {
        this.$emit("close", value);
        if (value === false) this.$emit("showOrder", true);
      },
      get: function get() {
        return this.subscribeVisible;
      }
    }
  },
  data: function data() {
    return {
      memberVisible: false,
      searchCountryKeyword: "",
      countryInvalidVisible: false,
      subTemplateId: process.env.VUE_APP_FILE_NOTICE,
      subTemplateIdBeach: process.env.VUE_APP_FILE_NOTICE_BACTH
    };
  },
  methods: {
    // 错误提示
    subError: function subError(e) {
      // this.$toast("subError"+e);
      console.log("subError" + e.errMsg);
    },
    // 我这里判断是必须把复数模板全部订阅
    success: function success(e) {
      // this.$toast("success"+e);
      console.log("success" + e);
      this._waitOrderVisible = false;
      if (this.beachRechargeBol) {
        this.$router.push({
          path: "/batch/recharge"
        });
      } else {
        if (this.showMember) {
          this.memberVisible = true;
        } else {
          this.$router.push({
            path: "/user/recharge-record"
          });
        }
      }
      console.log("参与成功");
    } // async closeWaitOrder() {
    //   // await cancelOrder(this.waitOrderInfo.yqqNo)
    //   this._waitOrderVisible = false;
    //   if (this.beachRechargeBol) {
    //     this.$router.push({ path: "/batch/recharge" });
    //   } else {
    //     this.$router.push({ path: "/user/recharge-record" });
    //   }
    // },
  }
};