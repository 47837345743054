var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("van-popup", {
    staticClass: "waitOrderPopup",
    attrs: {
      "safe-area-inset-bottom": "",
      "close-on-click-overlay": false,
      closeable: true,
      "close-on-popstate": true,
      "get-container": "#app"
    },
    model: {
      value: _vm._waitOrderVisible,
      callback: function callback($$v) {
        _vm._waitOrderVisible = $$v;
      },
      expression: "_waitOrderVisible"
    }
  }, [_c("p", {
    staticClass: "waitOrderPopup-title"
  }), _c("div", {
    staticClass: "waitOrderPopup-content"
  }, [_c("div", {
    staticClass: "waitOrderPopup-item"
  }, [_c("label", [_vm._v("是否接收通知?"), _c("br"), _c("br"), _vm._v("及时获取充值结果提醒")])])]), _c("div", {
    staticClass: "waitOrderPopup-button"
  }, [_c("wx-open-subscribe", {
    attrs: {
      template: _vm.beachRechargeBol ? _vm.subTemplateIdBeach : _vm.subTemplateId,
      id: "subscribe-btn"
    },
    on: {
      success: _vm.success,
      error: _vm.subError
    }
  }, [_c("script", {
    attrs: {
      slot: "style",
      type: "text/wxtag-template"
    },
    slot: "style"
  }, [_vm._v(' <style> .btn2 { color: #00B823; font-size: 18px; background-color: #fff; height: 30px; line-height: 40px; margin-top:7%; position: relative; left : 0px; top:1px; border: none; text-align: center; } </style> <!-- <Button class="btn2">确 认</Button> --> <!-- <button class="btn2">确 认</button> --> ')]), _c("script", {
    attrs: {
      type: "text/wxtag-template"
    }
  }, [_vm._v(' <button class="btn2">确 认</button> ')])])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };